export default {
  large: (rules) => `
    @media screen and (min-width: 1170px) {
      ${rules}
    }
  `,
  mid: (rules) => `
    @media screen and (max-width: 1170px) {
      ${rules}
    }
  `,
  small: (rules) => `
    @media screen and (max-width: 997px) {
      ${rules}
    }
  `,
  tiny: (rules) => `
    @media screen and (max-width: 739px) {
      ${rules}
    }
  `
};
