import { createGlobalStyle } from 'styled-components';
import Typography from 'typography';
import theme from 'typography-theme-sutro';

const typography = new Typography({
  ...theme,
  bodyWeight: 400,
  headerWeight: 600,
  bodyFontFamily: ["Roboto", "Open Sans", "sans-serif"],
});
export const rhythm = typography.rhythm;

typography.injectStyles();

export const GlobalStyle = createGlobalStyle`
  body {
    margin: unset;
    // a {
    //   color: #ff304f;
    // }
  }
`;
