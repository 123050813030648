import React from 'react';
import styled from 'styled-components';
// import { Link } from 'gatsby';
import { GlobalStyle, rhythm } from '../style/global';

const Container = styled.div`
  padding: ${rhythm(0.5)} ${rhythm(1.5)};

  nav {
    a {
      font-size: ${rhythm(0.65)};
    }
    & > * + * {
      margin-left: ${rhythm(1)};
    }
  }
`;

function Layout({
 Content, children,
}) {
  return (
    <>
      <GlobalStyle />
      <Container>
        {/* <header>
          <nav>
            <Link to="/about">About</Link>
            <Link to="/art">Art</Link>
            <Link to="/blog">Blog</Link>
          </nav>
        </header> */}
        <Content>
          {children}
        </Content>
      </Container>
    </>
  );
}

export default Layout;
