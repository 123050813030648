import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import Hero from '../components/hero';
import { rhythm } from '../style/global';
import BP from '../style/breakpoints';

const Content = styled.div`
  margin: 0 auto;
  padding: 0 ${rhythm(2)};
  max-width: 1440px;
  ${BP.small(`
    margin: 0;
    padding: 0 ${rhythm(1.5)};
  `)}
  ${BP.tiny`
    padding: 0;
  `}
`;

const Skills = styled.ul`
  margin-top: ${rhythm(1.5)};
  color: grey;
  columns: 3;
  list-style: none;
  ${BP.large(`
    font-size: ${rhythm(0.75)};
  `)}
  ${BP.mid(`
    font-size: ${rhythm(0.7)};
  `)}
  ${BP.small(`
    margin-top: ${rhythm(1.25)};
    columns: 2;
  `)}
  ${BP.tiny(`
    font-size: ${rhythm(0.75)};
    columns: 1;
  `)}
`;

const Contact = styled.footer`
`;

const SocialMediaLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

function Profile () {
  return (
    <Layout Content={Content}>
      <Hero />
      <Skills>
        <li>React</li>
        <li>Node</li>
        <li>Python</li>
        <li>Flask</li>
        <li>HTML/CSS</li>
        <li>Sass</li>
        <li>terraform</li>
        <li>Amazon Web Services</li>
        <li>Microsoft Azure</li>
        <li>Google Cloud Platform</li>
        <li>Docker</li>
        <li>Kubernetes</li>
        <li>Remote teams</li>
        <li>Cross-cultural interactions</li>
        <li>Computer Science degree</li>
      </Skills>
      <Contact>
        <h1>
          Want to connect?
          <div><span role="img" aria-label="email">✉️</span> <a href="mailto:michaelperng@gmail.com">Contact me.</a></div>
        </h1>
        <SocialMediaLinks>
          <a href="https://linkedin.com/in/michaelperng">LinkedIn</a>
          <a href="https://github.com/mperng">Github</a>
        </SocialMediaLinks>
      </Contact>
    </Layout>
  );
}

export default Profile;
