import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImg from 'gatsby-image';
import styled from 'styled-components';
import BP from '../style/breakpoints';
import { rhythm } from '../style/global';

const Container = styled.div`
  display: flex;
  align-items: center;
  ${BP.tiny(`
    flex-direction: column;
  `)}
`;

const Profile = styled.div`
  margin-top: ${rhythm(3)};
  ${BP.large(`
    margin-right: ${rhythm(1.5)};
    max-width: ${rhythm(15)};
  `)}
  ${BP.mid(`
    margin-right: ${rhythm(1)};
    max-width: ${rhythm(12)};
  `)}
  ${BP.small(`
    max-width: ${rhythm(21)};
    margin-right: 0;
  `)}
  ${BP.tiny(`
    margin: 0;
    order: 1;
  `)}
`;

const BigImg = styled(GatsbyImg)`
  border-radius: 50%;
  ${BP.large(`
    width: ${rhythm(17)};
  `)}
  ${BP.mid(`
    min-width: ${rhythm(15)};
  `)}
  ${BP.small(`
    display: none;
  `)}
  ${BP.tiny(`
    display: block;
    margin-bottom: ${rhythm(0.5)};
    min-width: ${rhythm(12)};
    order: 0;
  `)}
`;

const SmallerImg = styled(GatsbyImg)`
  border-radius: 50%;
  margin-left: ${rhythm(1.5)};
  margin-bottom: ${rhythm(1)};
  width: ${rhythm(8)};
  ${BP.large(`
    display: none;
  `)}
  ${BP.mid(`
    display: none;
  `)}
  ${BP.small(`
    display: block;
  `)}
  ${BP.tiny(`
    display: none;
  `)}
`;

const Name = styled.div`
  font-weight: 600;
  ${BP.large(`
    font-size: ${rhythm(1.5)};
  `)}
  ${BP.mid(`
    font-size: ${rhythm(1.25)};
  `)}
  ${BP.tiny(`
    font-size: ${rhythm(1.2)};
  `)}
`;

const Tagline = styled.div`
  font-size: ${rhythm(0.75)};
  font-weight: 600;
  margin-bottom: ${rhythm(1)};
`;

const NameAndTagContainer = styled.div`
  ${BP.large(`
    display: block;
  `)}
  ${BP.mid(`
    display: flex;
    align-items: center;
  `)}
  ${BP.tiny(`
    display: flex;
    align-items: center;
  `)}
`;

const Description = styled.div`
  ${BP.large(`
    max-width: 45ch;
    font-size: ${rhythm(0.7)};
  `)}
  ${BP.mid(`
    font-size: ${rhythm(0.65)};
  `)}
`;

function Hero() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "michael-perng.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Container>
      <Profile>
        <NameAndTagContainer>
          <div>
            <Name>Michael Perng</Name>
            <Tagline>&mdash; software developer</Tagline>
          </div>
          <SmallerImg
            fluid={data.file.childImageSharp.fluid}
            alt="Michael Perng"
          />
        </NameAndTagContainer>
        <Description>
          <p>
            I'm a full stack developer with 4+ years of experience in various technologies and settings.
          </p>
          <p>
            I have built multiple web applications from the ground up and am knowledgeable about server-side and client-side web development,
            as well as Cloud infrastructure.
          </p>
        </Description>
      </Profile>
      <BigImg
        fluid={data.file.childImageSharp.fluid}
        alt="Michael Perng"
      />
    </Container>
  );
}

export default Hero;
